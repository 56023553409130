import { Box, createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { NavBar } from "./NavBar";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb300",
            light: "#ffe54c",
            dark: "#c68400"
        },
        secondary: {
            main: "#4dd0e1",
            light: "#88ffff",
            dark: "#009faf"
        }
    }
});

export const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <Box>
            <NavBar />
        </Box>
    </ThemeProvider>
);
