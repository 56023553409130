import {
    AppBar, Toolbar, Typography
} from "@mui/material";
import React from "react";

export const NavBar: React.FC = () => (
    <AppBar position="sticky">
        <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                UltiDrills
            </Typography>
        </Toolbar>
    </AppBar>
);
